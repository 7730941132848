.sessionControl {
  display: flex;
  gap: 0.75rem;
  align-self: center;
}

.sessionControlItem {
  margin-top: 2.5rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #e5e5e5;
  border-radius: 50%;
  transition: background-color 0.4s;
}

.sessionControlItemActive {
  background-color: #305569;
}

@media screen and (max-width: 550px) {
  .sessionControl {
    gap: 0.75rem;
  }

  .sessionControlItem {
    width: 0.625rem;
    height: 0.625rem;
  }
}
