.card {
  padding: 1.25rem;
  width: min(22.5rem, 100%);
  background-color: #fff;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.cardBackButton {
  width: 3rem;
  height: 3rem;
  text-align: left;
}

.cardTitle {
  margin-top: 0.5rem;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 120%;
  color: #606061;
}

.cardDivider {
  margin: 2rem 0;
  display: block;
  width: 100%;
  border: 0.5px #305568 solid;
}

.cardDataRowOne {
  display: flex;
  gap: 1rem;
}

.cardDataRowOne > img {
  width: 1.5rem;
  height: 1.5rem;
}

.cardDataRowOne > div {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.cardDataRowOneTitle {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 120%;
  color: #3d555a;
}

.cardDataRowOneSubtitle {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.cardDataTextBody {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.cardDataTextBodyPlus {
  color: #4b93ff;
}

.cardDataTextBodySubtraction {
  color: #b71d1d;
}

.cardSectionInvestmentAmount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardTextBody {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.fontWeightLight {
  font-weight: 300;
}

.fontWeightBold {
  font-weight: 600;
}

.button {
  margin-top: 2rem;
  width: min(15rem, 90%);
  height: 3rem;
  background: #305568;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
