.modal {
  width: min(22rem, 90%);
  height: 12rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modalMessage {
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
  color: #3d555a;
}

.modalButton {
  height: 3.5rem;
  background: #305569;
  color: #fff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  border: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
