.labelType {
  font-size: 1rem;
  line-height: 120%;
}

.textValue {
  align-self: flex-end;
  font-size: 1.125rem;
  color: #305569;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.buttonInterruptOrder {
  font-size: 1.125rem;
  color: #305569;
  display: flex;
  align-items: center;
  text-align: center;
}

.transparentDiv {
  padding-right: 0.5rem;
}

.textFinished {
  align-self: center;
  font-size: 1.125rem;
  text-align: center;
  color: #a2a2a3;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.textAmountFinished {
  align-self: center;
  text-align: center;
  font-size: 1.125rem;
  color: #a2a2a3;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.textInvestorTypePaid,
.textInvestorTypePending {
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
}

.textInvestorTypePaid {
  color: #305569;
}

.textInvestorTypePending {
  color: #a2a2a3;
}

.investorCard {
  padding-left: 0rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #fff;
  border-radius: 16px;
  width: 570px;
  height: 480px;
  line-height: 150%;
  text-align: center;
  color: #3d555a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  overflow-y: auto;
  max-height: 80vh;
}

.descModalContentTitle {
  margin-top: 50px;
  font-size: 1.6rem;
  font-weight: 400;
}

.descModalContentText {
  margin-top: 30px;
  font-size: 1.3rem;
  font-weight: 300;
}

.descModalContentValue {
  font-size: 1.4rem;
  font-weight: 500;
  color: #3d555a;
}

.buttonContainer {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.primaryButton,
.secondaryButton {
  width: min(150px, 100%);
  height: 38px;
  font-size: 14px;
  line-height: calc(1rem * 1.5);
  border-radius: 28px;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
}

.primaryButton {
  background-color: #305569;
  color: #fff;
  margin-left: 40px;
}

.secondaryButton {
  color: #305569;
  border: 1px solid #305569;
  margin-right: 40px;
}

@media (max-width: 570px) {
  .buttonInterruptOrder {
    padding-left: 1rem;
  }

  .textFinished {
    padding-left: 0.5rem;
  }

  .transparentDiv {
    padding-right: 0rem;
  }

  .textAmountFinished {
    padding-right: 0.4rem;
  }

  .modalContent {
    width: 80%;
    height: 400px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 120%;
  }

  .descModalContentTitle {
    font-size: 1.3rem;
    margin-top: 30px;
  }

  .descModalContentText {
    font-size: 1rem;
    margin-top: 30px;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
    height: 38px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: space-between;
  }
}