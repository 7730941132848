.contacts {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.contactItem {
  width: 100%;
  /* max-width: 31.5rem; */
  height: 4rem;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.contactItem > p {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 140%;
  color: #3D555A;
}

.contactItem > img {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 2rem;
}

@media screen and (max-width: 550px) {
  .contactItem {
    height: 3.5rem;
  }

  .contactItem > p {
    font-size: 1rem;
  }

  .contactItem > img {
    width: 1.25rem;
    height: 1.25rem;
    left: 1rem;
  }
}
