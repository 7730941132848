.containerListContracts {
  width: 100%;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 2rem;
  width: 100%;
  height: 8rem;
  background: #fff;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 120%;
  color: #305568;
}

.gone {
  position: fixed;
  left: -99999px;
  top: -99999px;
  visibility: hidden;
  width: 0;
  height: 0;
  appearance: none;
}

.containerTable {
  height: 500px;
  overflow-y: scroll;
  /* margin-bottom: 5rem; */
}

.messageEmptyTable {
  padding: 0 1rem;
  margin-top: 3rem;
  font-size: 1rem;
  color: #898686;
  text-align: center;
}

@media screen and (max-width: 660px) {
  .header {
    height: auto;
    padding: 1.5rem 2rem;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  .headerTitle {
    font-size: 1.25rem;
  }
}
