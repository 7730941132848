.primaryButton {
  margin-top: 2rem;
}

.formInputPassword {
  position: relative;
  display: flex;
  align-items: center;
}

.buttonIconEye {
  position: absolute;
  right: 0.75rem;
}

.buttonIconEye > img {
  width: 1.5rem;
  height: 1.5rem;
}
