.card {
  padding: 2rem;
  width: 100%;
  max-width: 43.75rem;
  /* max-height: 52rem; */
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
}

.h2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 110%;
  color: #305568;
}

.questionsList {
  max-height: 52rem;
  margin-top: 3rem;
  overflow-y: scroll;
}

.questionsList::-webkit-scrollbar {
  width: 4px;
}

.questionsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.questionsList::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.questionsList::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.questionsDivider {
  width: 95%;
  margin: 3rem 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.questionSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  list-style: none;
}

.questionSummary::-webkit-details-marker {
  display: none;
}

details[open] .questionSummaryImg {
  transform: rotate(180deg);
}

details[open] .questionAnswer {
  transform: scaleY(1);
}

/* .questionSummary:focus .questionSummaryImg{
  transform: rotate(180deg);
} */

.questionSummaryTitle {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 115%;
  color: #305568;
}

.questionSummaryImg {
  margin-right: 2rem;
  width: 1rem;
  height: 1rem;
  transition: transform 0.4s;
}

.questionAnswer {
  margin: 1.5rem 2rem 0 0;
  transition: 0.4s;
  transform: scaleY(0);
  transform-origin: top;
}

.questionAnswer > p {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.questionAnswer > p + p {
  margin-top: 1rem;
}

@media screen and (max-width: 550px) {
  .card {
    padding: 1.25rem 1rem;
  }

  .h2 {
    font-size: 1.25rem;
  }

  .questionSummaryTitle {
    font-size: 1.125rem;
  }

  .questionsDivider {
    width: 90%;
    margin: 2rem 0;
  }

  .questionAnswer > p {
    font-size: 0.875rem;
  }
}
