.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 2.5rem;
  width: min(32.6875rem, 90%);
  height: 39.5625rem;
  background-color: #fff;
  border-radius: 0.5rem;

  overflow-y: auto;
}

.modal::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.modal::-webkit-scrollbar-button:start:decrement,
.modal::-webkit-scrollbar-button:end:increment {
  display: none;
}

.modal::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 6px;
}

.modal::-webkit-scrollbar-thumb:vertical {
  background-color: #305568;
  -webkit-border-radius: 6px;
}

.modal::-webkit-scrollbar-thumb:horizontal {
  background-color: #305568;
  -webkit-border-radius: 6px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #1a1a1a;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 120%;
}

.description {
  margin-top: 1.5rem;
  color: #333131;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  text-align: justify;
}

@media screen and (max-width: 550px) {
  .modal {
    padding: 1.25rem 0.75rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 120%;
  }

  .description {
    font-size: 0.875rem;
  }
}
