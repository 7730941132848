.title {
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
  font-weight: 300;
  color: #3d555a;
}

.dataBank {
  width: 100%;

  display: flex;
  align-items: center;
  gap: 8rem;
  margin-top: 0.6rem;

  /* overflow-x: auto; */
}

.name {
  /* margin-top: 1rem; */
  display: block;
  font-size: 1.25rem;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
}

.dataItem {
  /* margin-top: 1rem; */
  color: #606061;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
}

.dataItem > strong {
  color: #305568;
}

@media screen and (max-width: 1199px) {
  .dataBank {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}
