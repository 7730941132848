.button {
  flex: 1;
  height: 3rem;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  color: #606061;
  border: 1px solid #305569;
  cursor: pointer;
  background: transparent;
}

.buttonActive {
  background: #305569;
  color: #fff;
}

.buttonLeft {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.buttonRight {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
