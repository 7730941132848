.verticalProgress {
	width: 8px;
	height: 1rem;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: row;
	padding-bottom: 1rem;
	margin-left: 3.5rem;
	margin-top: 2rem;
}

.verticalProgressBar {
	background-color: #f0f2f5;
	width: 100%;
	transition: 30rem;
	height: 20rem;
}

.fill {
	background-color: #272729;
	width: 100%;
	transition: 20rem;
}

@media (max-width: 415px) {
	.verticalProgress {
		margin-left: 2rem;
	}
}