.sectionListLoans {
  margin-top: 2rem;
  padding-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 2rem 2.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;
}

.headerInformation {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: flex-end;
}

.headerInformation > h2 {
  color: #305568;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 120%;
}

.containerTable {
  height: 500px;
  overflow-y: scroll;
}

.messageEmptyTable {
  padding: 0 1rem;
  margin-top: 3rem;
  font-size: 1rem;
  color: #898686;
  text-align: center;
}

.primaryButton {
  margin: 3rem 2rem 0 0;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305568;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: flex-end;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 550px) {
  .header {
    padding: 2rem;
  }

  .primaryButton {
    margin: 2.5rem 0 0 0;
    align-self: center;
  }

  .messageEmptyTable {
    font-size: 0.875rem;
  }
}
