.card {
  padding: 1.25rem;
  width: 100%;
  /* max-width: 34rem; */
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 110%;
  color: #305568;
}

.illustration {
  margin: 10rem 0;
  height: 10rem;
  align-self: center;
}

.illustration2 {
  margin: 2.5rem 0 1.5rem 0;
  align-self: center;
  height: 10rem;
}

.primaryButton {
  width: min(16rem, 100%);
  height: 3rem;
  background: #305568;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  align-self: center;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
}

.textBody {
  max-width: 75%;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  align-self: center;
}

.textBody + .textBody {
  margin-top: 0.75rem;
}

.containerControlButtons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.buttonSessionControl {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.buttonSessionControl:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.buttonSessionControl > img {
  width: 2rem;
  height: 2rem;
}

.buttonBack {
  position: absolute;
  left: 1.5rem;
}

.buttonNext {
  position: absolute;
  right: 1.5rem;
}

.primaryButtonLastSection {
  margin-top: 2.5rem;
  z-index: 100;
}

@media screen and (max-width: 550px) {
  .card {
    padding: 1.25rem 1rem;
  }

  .h2 {
    font-size: 1.25rem;
  }

  .illustration {
    margin: 7rem 0;
    width: 80%;
  }

  .illustration2 {
    width: 80%;
  }

  .textBody {
    max-width: 100%;
    font-size: 0.875rem;
  }

  .containerControlButtons {
    /* align-items: flex-end;
    bottom: 1rem; */
    margin-top: -1.5rem;
    position: initial;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonBack {
    position: initial;
    left: 0;
  }

  .buttonNext {
    position: initial;
    right: 0;
  }
}
