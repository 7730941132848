:root {
  --gray-100: #898686;
  --gray-200: #504e4e;
  --gray-300: #333131;
  --gray-400: #1a1a1a;
}

.mainContainer {
  margin: 0 auto;
  width: min(82rem, 95%);
  display: flex;
  flex-direction: column;
}

.logo {
  margin-top: 1.5rem;
  height: 7rem;
}

.titlePage {
  margin-top: 4rem;
  color: #1a1a1a;
}

.h40 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 110%;
}

.sectionForm {
  margin: 4rem 0 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h24 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 110%;
}

.b16 {
  font-size: 1rem;
  line-height: 150%;
}

.b14 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
}

.card {
  padding: 1.5rem;
  width: min(29.5rem, 95%);
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #dbd7d7;
}

.primaryButton {
  width: 100%;
  height: 3rem;
  background: #305569;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

.formInputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.formInputGroup + .formInputGroup {
  margin-top: 1.25rem;
}

.formLabel {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--gray-300);
}

.formInput {
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f7f7;
  border-radius: 0.3125rem;
  border: 1px solid #dbd7d7;
  font-size: 1rem;
  color: var(--gray-300);
  outline: none;
  transition: border-color 0.2s;
}

.formInput:focus,
.formInput:focus-within {
  border-color: var(--gray-300);
}

.formInput::placeholder {
  color: var(--gray-100);
}

.passwordVisibilityButton {
  margin-top: 30px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 0.9rem;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .logo {
    margin-top: 0.75rem;
    height: 5.5rem;
  }

  .h40 {
    font-size: 1.75rem;
  }

  .h24 {
    font-size: 1.25rem;
  }

  .titlePage {
    margin-top: 2.5rem;
    text-align: center;
  }

  .card {
    padding: 0.75rem;
    margin-bottom: 2rem;
  }

  .sectionForm {
    margin-top: 2rem;
  }

  .formLabel {
    font-size: 0.8125rem;
  }

  .formInput {
    font-size: 0.875rem;
  }

  .primaryButton {
    font-size: 0.875rem;
  }
}
