.containerCard {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.backButton {
  width: 2.5rem;
  height: 2.5rem;
  text-align: left;
}

.backButton > img {
  width: 2rem;
  height: 2rem;
}

.title {
  color: var(--gray-400);
}

.mainContent {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mainContent > p {
  color: var(--gray-300);
}

.button {
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .mainContent > p {
    font-size: 0.875rem;
  }

  .mainContent {
    margin-top: 1.5rem;
  }
}
