.card {
  width: 23.75rem;
  background: #f2f4f5;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.header {
  padding: 1.5rem;
  background: linear-gradient(270deg, #305569 0%, #487e68 100%);
  border-radius: 1rem 1rem 0px 0px;
}

.backButton {
  width: 3rem;
  height: 3rem;
  text-align: left;
}

.title {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 110%;
  color: #fff;
}

.mainContent {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInputGroup {
  width: 100%;
}

.formInputGroup + .formInputGroup {
  margin-top: 0.75rem;
}

.formInputRadio {
  appearance: none;
  position: fixed;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}

.formInputRadio:checked ~ .formLabel {
  background-color: #667dbb;
  color: #fff;
}

.formLabel {
  padding: 0 1.25rem;
  width: 100%;
  height: 6rem;
  background-color: #fff;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.formLabel:hover {
  background-color: #667dbb;
  color: #fff;
}

.button {
  margin-top: 2rem;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305569;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.button:hover {
  filter: brightness(0.9);
}
