.listSteps {
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
}

.stepItem {
  height: min-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  position: relative;
  transition: 0.3s;
}

.stepItemActive > svg > path {
  fill: #3d555a;
}

.stepItemActive > span {
  color: #3d555a;
  font-weight: 500;
}

.stepItemActive::after {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  left: 0;
  right: 0;
  border: 2px solid #3d555a;
}

.stepItemDisabled > svg > path {
  fill: #898686;
}

.stepItemDisabled > span {
  color: #898686;
  font-weight: 400;
}

.stepItemDefault > svg > path {
  fill: #1a1a1a;
}

.stepItemDefault > span {
  color: #1a1a1a;
  font-weight: 400;
}

.stepItemIcon {
  width: 2rem;
  height: 2rem;
}

.stepItemTitle {
  font-size: 1.125rem;
  line-height: 150%;
  color: #1a1a1a;
  text-align: center;
}

@media screen and (max-width: 820px) {
  .listSteps {
    max-width: 100%;
    overflow-x: auto;
    min-height: 8.5rem;
    gap: 1.25rem;
  }

  .stepItem {
    flex: 1 0 7rem;
  }
}

@media screen and (max-width: 600px) {
  .listSteps {
    margin-top: 2rem;
    min-height: auto;
    gap: 1rem;
    justify-content: center;
  }

  .stepItem {
    flex: initial;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
  }

  .stepItem > *,
  .stepItem::after {
    display: none;
  }

  .stepItemActive {
    background: #305569;
  }

  .stepItemDisabled,
  .stepItemDefault {
    background: rgba(89, 162, 132, 0.2);
  }
}
