.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  position: relative;
}

.table > thead {
  background-color: #305568;
  height: 3rem;
  color: #ffffff;
  font-size: 1.125rem;
  position: sticky;
  top: 0;
}

.table th,
.table td {
  padding: 1rem;
}

.table td {
  font-size: 1rem;
  color: #333131;
}

.headerButtonAction {
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.2s;
}

.headerButtonAction:hover {
  transform: scale(1.25);
}

.containerHeaderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 2rem; */
  position: relative;
}

.actionsButton {
  font-size: 1rem;
  color: #305568;
}

.colorStatusEnable {
  color: #305568;
}

.colorStatusDisable {
  color: #f5333f;
}

@media screen and (max-width: 550px) {
  .table > thead {
    font-size: 0.875rem;
  }

  .table td {
    font-size: 0.875rem;
  }

  .containerHeaderTitle {
    gap: 1.5rem;
    white-space: nowrap;
  }

  .actionsButton {
    font-size: 0.875rem;
  }
}
