.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 300;
}

.headerCloseButton,
.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.headerCloseButtonRight {
  align-self: flex-end;
}

.messageInfo {
  margin-top: 2.5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

.illustration {
  margin-top: 1.5rem;
  width: min(21.875rem, 100%);
  align-self: center;
}

.containerCode {
  margin-top: 2rem;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.containerCode strong {
  font-size: 1.125rem;
  line-height: 140%;
}

.containerCode > div > p {
  font-size: 1rem;
  line-height: 150%;
}

.containerCodeActionButton,
.containerCodeActionButton > img {
  width: 1.5rem;
  height: 1.5rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.formGrid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.formInputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.formInputGroup > label,
.formInputGroup > p {
  font-size: 1rem;
  line-height: 150%;
}

.formInputGroup > label {
  font-weight: 500;
}

.formInputGroup > p {
  color: #504e4e;
}

.formSelect {
  padding: 0 0.5rem;
  height: 2.5rem;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #504e4e;
  border-radius: 0.25rem;
}

.primaryButton {
  margin-top: 2.5rem;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305568;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

.containerActionButtons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
