.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(29.5rem, 90%);
  /*min-height: 18.75rem;*/
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 110%;
  color: #1a1a1a;
}

.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #E54F4F;
  border-radius: 0.3125rem;
}

.closeButton > img {
  width: 2rem;
  height: 2rem;
}

.containerIcon {
  margin-top: 2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D2F6E7;
  align-self: center;
}

.containerIcon > img {
  width: 2.5rem;
  height: 2.5rem;
}

.description {
  margin: 1rem 0 2rem 0;
  font-size: 1rem;
  line-height: 150%;
  color: #333131;
  text-align: center;
}

.linkButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 425px) {
  .modal {
    padding: 0.75rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .containerIcon {
    margin-top: 1.5rem;
  }

  .description {
    margin: 0.5rem 0 1.5rem 0;
    font-size: 0.875rem;
  }
}
