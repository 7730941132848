.card {
  padding: 2.5rem;
  width: 100%;
  min-height: 8rem;
  background: linear-gradient(270deg, #305568 0%, #396277 100%);
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  color: #ffffff;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  line-height: 115%;
}

.listData {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.dataItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dataItemIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.dataItemLabel {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 400;
  color: #78c5ec;
  text-transform: uppercase;
}

.dataItemAmount {
  color: #fff;
  font-size: 1.125rem;
  line-height: 150%;
  text-transform: uppercase;
}

@media screen and (max-width: 550px) {
  .card {
    padding: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 120%;
  }
}

@media screen and (max-width: 375px) {
  .listData {
    flex-wrap: wrap;
    justify-content: center;
  }
}
