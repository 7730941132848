textarea {
  font-family: "Roboto", sans-serif;
  resize: none;
}

.label {
  font-size: 0.875rem;
  font-weight: 300;
  color: #3d555a;
  line-height: calc(0.875rem * 1.5);
}

.input {
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  color: #3d555a;
  outline: 0;
  border: 0;
}

.editButton {
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid #3d555a;
}

.editButton img {
  width: 1.5rem;
  height: 1.5rem;
}

.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 50%;
  margin: -3rem 0 0 2rem;
}

.closeButton img {
  width: 1rem;
  height: 1rem;
}

.button {
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305569;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
}

.button:hover {
  filter: brightness(0.9);
}

@media (max-width: 700px) {
  .closeButton {
    margin: 0;
    position: absolute;
    right: 0;
    top: -5rem;
  }
}
