.containerCard {
  display: flex;
  justify-content: space-between;
}

.inputRadio {
  appearance: none;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.containerRisk {
  display: block;
  width: min(370px);
  height: 100%;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
}

.containerRisk:hover {
  opacity: 0.7;
}

.containerRiskHeaderIcon {
  width: 2rem;
  height: 2rem;
}

.containerRiskLabel {
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
  color: #3d555a;
}

.containerRiskLabel ~ .containerRiskLabel {
  display: flex;
  gap: 0.25rem;
  /* comitede */
}

.fontWeightLight {
  font-weight: 300;
}

.fontWeightBold {
  font-weight: 600;
}

.containerRiskLabel > .containerRiskLabel:first-child {
  margin-right: 0.25rem;
}

.containerRiskSignalPlus {
  color: #4b93ff;
}

.containerRiskSignalMinus {
  color: #db8f1b;
}

.containerRiskSignalEqual {
  color: #3d555a;
}

.primaryButton {
  width: min(15.5rem, 90%);
  height: 3rem;
  background-color: #305568;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
}

.primaryButton:disabled {
  box-shadow: 0;
  background-color: #dae0e1;
}

.messageRelease {
  font-size: 1.25rem;
  line-height: 115%;
  color: #f5f5f5;
  text-align: center;
}

.footer {
  text-align: center;
  font-size: 1rem;
  font-weight: 400;

  background-color: #db8f1b;
  padding: -24px;
  padding: 20px;

  border-radius: 20px;
}
