.option {
  font-size: 0.75rem;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1rem 2rem;
}

.option:hover {
  background-color: #f0f2f5;
}
