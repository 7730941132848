.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(33.5rem, 95%);
  max-height: 85vh;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 300;
}

.headerCloseButton,
.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.messageLabel {
  margin-top: 3rem;
  max-width: 80%;
  align-self: center;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 130%;
  text-align: center;
  color: var(--gray-300);
}

.code {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
  color: #305568;
}

.primaryButton {
  margin-top: 3rem;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305568;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}
