.card {
  padding: 1.5rem 2rem;
  width: min(22.5rem, 100%);
  background-color: #fff;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3d555a;
}

.title {
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 110%;
  text-align: center;
}

.illustration {
  margin: 1.5rem 0;
  width: 15.25rem;
  height: 7.625rem;
}

.textBody {
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

.textBody + .textBody {
  margin-top: 1rem;
}

.button {
  margin-top: 2rem;
  width: min(15rem, 90%);
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}
