.mainContent {
  height: 100%;
  min-height: 100vh;
  display: grid;
  place-content: center;
}

.container {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.containerIcon {
  width: 12.5rem;
  height: 12.5rem;
  background-color: #d2f6e7;
  display: grid;
  place-content: center;
  border-radius: 0.5rem;
}

.icon {
  width: 8rem;
  height: 8rem;
  /* animation: loop 4s linear infinite; */
}

.title {
  margin-top: 4rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 115%;
  color: #3d555a;
}

.textBody {
  margin-top: 1.25rem;
  width: 100%;
  max-width: 47.5rem;
  color: #504e4e;
  font-size: 1.25rem;
  line-height: 150%;
}

.textBody + .textBody {
  margin-top: 1rem;
}

.linkButton {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(26.5rem, 100%);
  height: 3rem;
  background: #305569;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.linkButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@keyframes loop {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 550px) {
  .containerIcon {
    width: 9rem;
    height: 9rem;
  }

  .icon {
    width: 6rem;
    height: 6rem;
  }

  .title {
    margin-top: 3rem;
    font-size: 2rem;
  }

  .textBody {
    margin-top: 1.25rem;
    font-size: 1rem;
  }

  .linkButton {
    margin-top: 3rem;
    font-size: 0.875rem;
  }
}
