.containerCard {
  width: min(54rem, 100%);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.backButton {
  width: 2.5rem;
  height: 2.5rem;
  text-align: left;
}

.backButton > img {
  width: 2rem;
  height: 2rem;
}

.title {
  color: var(--gray-400);
}

.sectionTerms {
  margin-top: 1.5rem;
  max-height: 26.25rem;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.sectionTerms > p {
  color: var(--gray-300);
}

.sectionTerms > ul,
.sectionTerms > ol {
  margin: 0.75rem 0;
  margin-left: 1.5rem;
}

.sectionTerms > ul {
  list-style: square;
}

.sectionTerms > ol {
  list-style: upper-alpha;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.termTextBody {
  padding-right: 0.25rem;
}

.termTextBody + .termTextBody {
  margin-top: 0.75rem;
}

.termSubtitle {
  margin: 1.5rem 0 0.75rem;
  font-size: 1.125rem;
  line-height: 130%;
}

.containerButton {
  margin-top: 1.5rem;
  width: min(26rem, 100%);
  align-self: center;
}

@media screen and (max-width: 600px) {
  .sectionTerms > p {
    font-size: 0.875rem;
  }
}
