button {
  cursor: pointer;
  background: transparent;
  border: 0;
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

button:disabled {
  transform: scale(1);
}

.headerBackButton {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: 0;
  border: 0;
}

.headerBackButton img {
  width: 1.5rem;
  height: 1.5rem;
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
}

.headerUpdateIcon {
  width: 1rem;
  height: 1rem;
}

.buttonAddBank {
  margin: 4rem 0;
  padding: 0.75rem 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #305569;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  align-self: center;
}

.buttonAddBank img {
  width: 1.5rem;
  height: 1.5rem;
}

.formPix {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formPixLabel {
  font-size: 1.5rem;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
}

.formPixInput {
  margin-top: 4rem;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
  font-weight: 300;
  color: #3d555a;
  width: 80%;
  outline: 0;
  border: 0;
  border-bottom: 2px solid #3d555a;
}

.formPixTextButton {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
  color: #305569;
}

.button {
  width: min(15rem, 90%);
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.button:hover {
  transform: scale(1.02);
}

.button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.button:disabled {
  transform: scale(1);
}

.secondaryButton {
  width: min(15rem, 90%);
  height: 3rem;
  background: transparent;
  color: #305569;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  border: 1px solid #305569;
  transition: transform 0.2s;
}

.secondaryButton:hover {
  transform: scale(1.02);
}

.formPixButton {
  margin: 16rem 0 3rem 0;
}

.formPixDataPixButton {
  margin: 8rem 0 3rem 0;
  align-self: center;
}

.formPixDataPixHat {
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  color: #3d555a;
  text-transform: uppercase;
}

.formPixDataPixValue {
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
  color: #3d555a;
}

.formBanks {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formBanksInput {
  appearance: none;
  display: none;
}

.formBanksLabel {
  border: 1px solid #edeaea;
  width: 7rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid #edeaea;
  transition: 0.3s;
}

.formBanksLabel:hover {
  border: 1px solid #305569;
}

.formBanksInput:checked ~ .formBanksLabel {
  border: 1px solid #305569;
}

.formBanksButton {
  margin: 8rem 0 3rem 0;
}

.formAccountForm {
  padding: 0 2rem;
  margin: 4rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.formAccountFormButton {
  margin-top: 10rem;
  align-self: center;
}

.formAmount {
  margin: 6rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formAmountLabel {
  font-size: 1.5rem;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
}

.formAmountReal {
  position: absolute;
  left: 0.5rem;
  font-size: 1.5rem;
  color: #305569;
}

.formAmountInput {
  padding: 0 0.5rem 0 2.5rem;
  width: 12rem;
  font-size: 2rem;
  line-height: calc(2rem * 1.3);
  color: #305569;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #3d555a;
  /* text-align: center; */
}

.formAmountInput::placeholder {
  color: rgba(0, 0, 0, 0.15);
}

.formAmountInputRange {
  width: 80%;
  margin-top: 1.5rem;
}

.formAmountBalance {
  margin-top: 2rem;
  max-width: 90%;
  text-align: center;
  font-size: 1.125rem;
  line-height: 150%;
  color: #3d555a;
}

.formAmountBalance + .formAmountBalance {
  margin-top: 0.75rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
}

.formAmountButton {
  margin-top: 16rem;
}

.confirmedWithdrawSecondaryButton {
  margin-top: 2rem;
}

.confirmedWithdrawBankIcon {
  width: 1.125rem;
  height: 1.125rem;
}

.confirmedWithdrawButton {
  margin: 7rem 0 3rem 0;
}

.lastIllustration {
  margin-top: 6rem;
  width: min(22.5rem, 90%);
  object-fit: cover;
}

.smsForm {
  margin: 2rem 0 3rem 0;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
}

.formLabel2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
  text-align: center;
  align-self: center;
}

.mt3 {
  margin-top: 3rem;
}

.mt6 {
  margin-top: 6rem;
}

.formAuxiliaryMessage {
  margin: 3rem 0 0.5rem 0;
  width: 70%;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  color: #3d555a;
  text-align: center;
  align-self: center;
}

.formButton {
  width: min(15rem, 100%);
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
}

.formInput2 {
  width: 100%;
  font-size: 2rem;
  line-height: calc(2rem * 1.3);
  color: #305569;
  border: 0;
  border-bottom: 2px solid #3d555a;
  font-weight: 300;
  outline: 0;
  text-align: center;
}

.formButtonStep2 {
  margin-top: 3.5rem;
  align-self: center;
}

.formButtonStep1 {
  margin-top: 13rem;
  align-self: center;
}

.form {
  margin: 2rem 0 3rem 0;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .confirmedWithdrawButton {
    margin: 3rem 0;
  }

  .formAmountButton {
    margin-top: 12rem;
  }
}

@media (max-width: 550px) {
  .formAccountForm {
    padding: 0 1rem;
    margin: 1.5rem 0 3rem 0;
    gap: 1.5rem;
  }

  .formAccountFormButton {
    margin-top: 2rem;
  }

  .formBanks {
    margin-top: 3rem;
  }

  .formBanksButton {
    margin: 3rem 0;
  }
}
