.modalLogoutcontainerButtons {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.modalLogoutcontainerButtons a,
.modalLogoutcontainerButtons button {
  flex: 1;
  height: 3rem;
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 700;
  border-radius: 0.5rem;
}

.modalLogoutcontainerButtons a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #305569;
  color: #fff;
}

.modalLogoutcontainerButtons button {
  border: 1px solid #fa5050;
  color: #fa5050;
}
