.backButton {
  margin: 1.5rem 0;
  width: 3rem;
  height: 3rem;
}

.cardAmountToInvest {
  padding: 1.5rem;
  width: 25rem;
  min-height: 20rem;
  background-color: #fff;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.cardAmountToInvestTitle {
  font-size: 1.25rem;
  line-height: 120%;
  font-weight: 300;
  color: #3d555a;
}

.formAmount {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formAmountReal {
  position: absolute;
  left: 0.5rem;
  font-size: 1.5rem;
  color: #305568;
}

.formAmountInput {
  padding: 0 0.5rem 0 2.5rem;
  width: 12rem;
  font-size: 2rem;
  line-height: calc(2rem * 1.3);
  color: #305568;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #3d555a;
  /* text-align: center; */
}

.formAmountInput::placeholder {
  color: rgba(0, 0, 0, 0.15);
}

.formAmountInputRange {
  width: 80%;
  margin-top: 1.5rem;
}

.formAmountBalance {
  margin-top: 1rem;
  max-width: 90%;
  text-align: center;
  font-size: 1.125rem;
  line-height: 150%;
  color: #3d555a;
}

.button {
  width: min(15rem, 90%);
  height: 3rem;
  background: #305568;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
}

.formAmountButton {
  margin-top: 2rem;
}

.formAmountButton:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.gone {
  width: 0;
  height: 0;
  position: absolute;
  left: -99999px;
  top: -99999px;
  visibility: hidden;
}

.containerRisk {
  width: 350px;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1rem;
}

.containerRiskHeaderIcon {
  width: 2rem;
  height: 2rem;
}

.containerRiskLabel {
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
  color: #3d555a;
}

.containerRiskLabel ~ .containerRiskLabel {
  display: flex;
  gap: 0.25rem;
}

.fontWeightLight {
  font-weight: 300;
}

.fontWeightBold {
  font-weight: 600;
}

.containerRiskLabel > .containerRiskLabel:first-child {
  margin-right: 0.25rem;
}

.containerRiskSignalPlus {
  color: #4b93ff;
}

.containerRiskSignalMinus {
  color: #db8f1b;
}

.containerRiskSignalEqual {
  color: #3d555a;
}
