.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 3rem;

  max-width: 45.375rem;
  height: 56rem;

  background-color: #fff;
  border-radius: 0.5rem;

  text-align: center;

  overflow-y: scroll;
}

.modal::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #3d555a;
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
}

.headerCloseButton,
.headerCloseButton > img {
  width: 2rem;
  height: 2rem;

  margin-bottom: 100px;
}

.instructions {
  color: #000;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: justify;
  line-height: 175%;
  margin-bottom: 1.5rem;
}

.subtitle {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: justify;
  line-height: 175%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.itemList {
  list-style: disc outside;
  margin-left: 3rem;
}


@media screen and (max-width: 550px) {
  .modal {
    padding: 1.25rem 0.75rem;
  }
  .title {
    font-size: 1.5rem;
    line-height: 120%;
  }
}
