button {
  cursor: pointer;
}

.headerBackButton {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: 0;
  border: 0;
}

.headerBackButton img {
  width: 1.5rem;
  height: 1.5rem;
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
}

.headerUpdateIcon {
  width: 1rem;
  height: 1rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smsForm {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.formLabel {
  width: 85%;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: calc(1.25rem * 1.5);
  color: #3d555a;
  text-align: center;
  align-self: center;
}

.formLabel2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: calc(1.5rem * 1.5);
  color: #3d555a;
  text-align: center;
  align-self: center;
}

.formInput {
  margin-top: 4rem;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #3d555a;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
  color: #3d555a;
  font-weight: 700;
  outline: 0;
  text-align: center;
}

.formInput::placeholder {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.2);
}

.formInput2 {
  width: 100%;
  font-size: 2rem;
  line-height: calc(2rem * 1.3);
  color: #305569;
  border: 0;
  border-bottom: 2px solid #3d555a;
  font-weight: 300;
  outline: 0;
  text-align: center;
}

.mt1 {
  margin-top: 1rem;
}

.mt24 {
  margin-top: 1.5rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt6 {
  margin-top: 6rem;
}

.maxWidth70 {
  max-width: 70%;
}

.formButton {
  width: 70%;
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
}

.formButtonStep1 {
  margin-top: 13rem;
  align-self: center;
}

.formButtonStep1:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.formButtonStep2 {
  margin-top: 3.5rem;
  align-self: center;
}

.formAuxiliaryMessage {
  margin: 3rem 0 0.5rem 0;
  width: 70%;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  color: #3d555a;
  text-align: center;
  align-self: center;
}

.text16 {
  font-size: 1rem;
  font-weight: 300;
  line-height: calc(1rem * 1.5);
}

.text24 {
  font-size: 1.5rem;
  line-height: calc(1.5rem * 1.5);
}

.colorBlue {
  color: #667dbb;
}

.colorGreenDark {
  color: #3d555a;
}

.qrCode {
  margin-top: 1rem;
  width: 12rem;
  height: 12rem;
}

.textButton {
  background: transparent;
  text-decoration: underline;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  color: #305569;
  border: 0;
  outline: 0;
}

.modal {
  width: min(22rem, 90%);
  height: 12rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modalMessage {
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.5);
  color: #3d555a;
}

.modalButton {
  height: 3.5rem;
  background: #305569;
  color: #fff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  position: absolute;
  bottom: -2rem;
  left: 0;
  right: 0;
  border: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.secondaryButton {
  width: min(15rem, 90%);
  height: 3rem;
  background: transparent;
  color: #305569;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  border: 1px solid #305569;
  transition: transform 0.2s;
}

.secondaryButton:hover {
  transform: scale(1.02);
}
