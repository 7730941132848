.modalTitle {
    display: flex;
    padding: 2rem;
    justify-content: center;
  }
  
  .title {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 400;
    color: #3D555A;
  }
  
  .modalButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 3rem;
  }
  
  .closeButton {
    display: flex;
    justify-content: flex-end;
    padding: 0.2rem 0.3rem;
  }
  
  
  @media screen and (max-width: 550px) {
    
  }