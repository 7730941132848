.sectionMessages {
  margin-top: 5rem;
}

.listMessages {
  margin: 0 auto;
  max-width: 100%;
  width: 45rem;
  /* width: min(45rem, 100%); */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* border: 1px solid #000; */
}

@media screen and (max-width: 768px) {
  .listMessages {
    gap: 1.5rem;
  }
}
