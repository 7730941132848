.containerCard {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
}

.title {
  color: var(--gray-400);
}

.form {
  margin-top: 2rem;
}

.rowAccount {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 60% auto;
  gap: 1rem;
}

.rowAccount + .rowAccount {
  margin-top: 1.25rem;
}

.rowAccount > div:last-child {
  margin-top: 0;
}

.formButton {
  margin-top: 1.5rem;
}

.auxilliaryText {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: #898686;
}

@media screen and (max-width: 550px) {
  .rowAccount {
    gap: 1rem;
  }

  .auxilliaryText {
    font-size: 0.75rem;
  }
}
