.walletItem {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletItemTooltipTitle {
  font-size: 0.875rem;
  line-height: 110%;
  text-transform: uppercase;
  color: #305568;
}

.walletItemTooltipBody {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 150%;
  max-width: 420px;
  width: 100%;
}

.walletItemTooltipFooter > a {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #5a0f7d;
  text-decoration: underline;
}

.walletItemIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.walletItemBody1 {
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.walletItemBody2 {
  margin-top: 0.5rem;
  color: #78c5ec;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  text-transform: uppercase;
  text-align: center;
}

.walletButton {
  width: 9.25rem;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.walletButton:hover {
  transform: scale(1.02);
}

.walletButtonAdd {
  color: #606061;
  background: #fabc1d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.walletButtonWithdraw {
  color: #305568;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.walletFooterSubtitle {
  color: #78c5ec;
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
}

.walletFooterIcon {
  margin-left: 0.5rem;
  width: 0.875rem;
  height: 0.875rem;
}

.walletFooterTitle {
  font-size: 2rem;
  line-height: calc(2rem * 1.3);
  color: #fff;
}
