.title {
  color: var(--gray-400);
}

.form {
  margin-top: 2rem;
}

.formButton {
  margin-top: 1.5rem;
}

.auxiliaryText {
  margin-top: 2rem;
  color: var(--gray-100);
  text-align: center;
}

.containerLinkLogin {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}

.containerLinkLogin > p {
  color: var(--gray-200);
}

.containerLinkLogin > a {
  font-size: 0.875rem;
}

@media screen and (max-width: 600px) {
  .form {
    margin-top: 1.25rem;
  }

  .auxiliaryText {
    font-size: 0.75rem;
  }
}
