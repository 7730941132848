.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.backButton {
  width: 2.5rem;
  height: 2.5rem;
  text-align: left;
}

.backButton > img {
  width: 2rem;
  height: 2rem;
}

.title {
  color: var(--gray-400);
}

.form {
  margin-top: 2rem;
}

.submitButton {
  margin-top: 1.5rem;
}

