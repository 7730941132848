.illustration {
  margin-top: 3rem;
  width: 24rem;
}

.secondaryButton {
  margin-top: 3rem;
  width: min(15rem, 100%);
  height: 3rem;
  background: transparent;
  color: #305569;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  border: 1px solid #305569;
  transition: transform 0.2s;
}

.secondaryButton:hover {
  transform: scale(1.02);
}
