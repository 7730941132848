.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #fff;
  border-radius: 8px;
  width: 476px;
  height: 336px;
  line-height: 150%;
  text-align: center;
  color: #3d555a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
}

.descModalContentTitle {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 92px;
  margin-left: 20px;
  margin-right: 20px;
}

.modalClose {
  width: min(150px, 100%);
  height: 38px;
  font-size: 14px;
  line-height: calc(1rem * 1.5);
  border-radius: 28px;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
  background-color: #305569;
  color: #fff;
  margin-top: 68px;
  margin-bottom: 42px;
}


.card {
  margin: 2rem 0;
  height: 333px;
  width: 100%;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background-color: #edeff2;
  display: flex;
  flex-direction: column;
  position: relative;
}

.overlay {
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border-radius: 1rem;
}

.messageRelease {
  font-size: 2rem;
  line-height: 115%;
  color: #f5f5f5;
  text-align: center;
}

.header {
  padding: 1rem 2rem;
  width: 100%;
  min-height: 5rem;
  background-color: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: nowrap;
}

.headerTitle {
  font-size: 1.625rem;
  line-height: 120%;
  font-weight: 300;
  color: #3d555a;
}

.headerNav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.headerSubscription {
  font-size: 1.125rem;
  list-style: 140%;
  font-weight: 300;
  color: #3d555a;
}

.ordersList {
  flex: 1;
  flex-direction: column;
  padding: 1rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow: auto;
}

.responseNotOrders {
  width: 100%;

  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
}

.cardOrder {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  width: 488px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.cardOrderSectionTop {
  display: flex;
  gap: 0.5rem;
}

.cardOrderDescription {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 140%;
  color: #3d555a;
}

.cardOrderAmount {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 140%;
  color: #305569;
  align-self: flex-end;
}

/* .textCancel {
  font-size: 1rem;
  margin-top: 10px;
  text-align: "right";
  color: #ff0000;
} */

.textButton {
  margin-top: 0.75rem;
  align-self: flex-end;
  font-size: 1.125rem;
  color: #305568;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.textButton > img {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 590px) {
  .headerTitle {
    font-size: 1.25rem;
  }

  .headerSubscription {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 460px) {
  .headerTitle {
    font-size: 1.1rem;
  }

  .headerSubscription {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 444px) {
  .headerTitle {
    font-size: 0.9rem;
  }

  .headerSubscription {
    font-size: 0.7rem;
  }

  .responseNotOrders {
    font-size: 1rem;
  }
  .cardOrder {
    padding: 1rem 1rem 0.5rem 1rem;
    width: 350px;
  }

  .cardOrderDescription {
    font-size: 1rem;
  }

  .cardOrderAmount {
    font-size: 1rem;
  }

  .textCancel {
    font-size: 0.5rem;
  }

  .responseNotOrders {
    font-size: 1rem;
  }
}
