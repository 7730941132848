.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(39.125rem, 90%);
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #1a1a1a;
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;
}

.description {
  margin-top: 1rem;
  color: #333131;
  font-size: 1rem;
  line-height: 150%;
}

.illustration {
  margin-top: 3rem;
  height: 14rem;
}

.documentsList {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}

.documentsList > li > strong {
  font-size: 1.25rem;
  line-height: 150%;
  color: #333131;
}

.primaryButton {
  margin-top: 3rem;
  width: min(26.5rem, 100%);
  height: 3rem;
  background: #305569;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 550px) {
  .modal {
    padding: 1.25rem 0.75rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 120%;
  }

  .description {
    font-size: 0.875rem;
  }

  .illustration {
    height: 10rem;
  }

  .documentsList {
    margin-top: 1rem;
  }

  .documentsList > li > strong {
    font-size: 1.125rem;
  }

  .primaryButton {
    font-size: 0.875rem;
  }
}
