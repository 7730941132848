.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 2.5rem;
  width: min(39.125rem, 90%);
  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #1a1a1a;
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;
}

.instructions {
  margin-top: 1rem;
  color: #333131;
  font-size: 1.2rem;
  line-height: 150%;
}

.description {
  margin-top: 1rem;
  color: #333131;
  font-size: 1rem;
  line-height: 150%;
  text-align: justify;
}

.primaryButton {
  margin-top: 3rem;
  width: min(26.5rem, 100%);
  height: 3rem;
  background: #305568;
  box-shadow: 0px 47px 170px rgba(12, 100, 16, 0.030926),
    0px 19.6355px 71.0219px rgba(12, 100, 16, 0.0444282),
    0px 10.4981px 37.9717px rgba(12, 100, 16, 0.055),
    0px 5.88513px 21.2866px rgba(12, 100, 16, 0.0655718),
    0px 3.12555px 11.3052px rgba(12, 100, 16, 0.079074),
    0px 1.30061px 4.70434px rgba(12, 100, 16, 0.11);
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 550px) {
  .modal {
    padding: 1.25rem 0.75rem;
  }

  .title {
    font-size: 1.5rem;
    line-height: 120%;
  }

  .description {
    font-size: 0.875rem;
  }

  .primaryButton {
    font-size: 0.875rem;
  }
}
