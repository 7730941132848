.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(44rem, 95%);
  max-height: 85vh;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  justify-content: space-between;
}

.headerCloseButton {
  width: 2rem;
  height: 2rem;
  align-self: flex-start;
}

.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.textBody {
  margin-top: 2.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: #504e4e;
}

@media screen and (max-width: 550px) {
  .textBody {
    font-size: 0.875rem;
  }
}
