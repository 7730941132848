.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(29.375rem, 95%);
  min-height: 18.75rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--gray-400);
}

.mainContent {
  margin: 2rem 0 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.containerIcon {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d2f6e7;
}

.containerIcon > img {
  width: 2.5rem;
  height: 2.5rem;
}

.message {
  max-width: 23.5rem;
  width: 100%;
  color: var(--gray-300);
  text-align: center;
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 0.75rem;
  }

  .containerIcon {
    width: 4rem;
    height: 4rem;
  }

  .containerIcon > img {
    width: 2rem;
    height: 2rem;
  }

  .message {
    font-size: 0.875rem;
  }
}
