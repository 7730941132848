.title {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
}

.paragraph {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: calc(1.125rem * 1.5);
  color: #3d555a;
}

.label {
  max-width: 20rem;
  font-size: 1.125rem;
}