.tag {
  padding: 0.375rem 0.75rem;
  display: inline;
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 120%;
}

.tagInfo {
  background-color: #9cb8ff;
  color: #0e2662;
}

.tagAlert {
  background-color: #ffe39c;
  color: #624a0e;
}

.tagDisabled {
  background-color: #bfbaba;
  color: #6f6b6b;
}
