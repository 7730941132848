.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(39.125rem, 90%);
  max-height: 85vh;
  overflow: auto;
  background: #fff;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  font-size: 2rem;
  line-height: 115%;
  font-weight: 600;
  color: #1a1a1a;
}

.subtitle {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 400;
  color: #333131;
}

.illustration {
  margin-top: 3rem;
  width: min(11.625rem, 100%);
  height: 14rem;
  align-self: center;
}

.textBody16 {
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 400;
  color: #333131;
}

.pendingItem {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  line-height: 150%;
  font-weight: 500;
  color: #333131;
}

.textDescription {
  margin-top: 3.5rem;
  max-width: 18.75rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 400;
  color: #898686;
  align-self: center;
}

.primaryButton {
  margin-top: 2rem;
  width: min(26.5rem, 100%);
  min-height: 3rem;
  background: #305569;
  box-shadow: 0px 47px 170px rgba(12, 100, 16, 0.030926),
    0px 19.6355px 71.0219px rgba(12, 100, 16, 0.0444282),
    0px 10.4981px 37.9717px rgba(12, 100, 16, 0.055),
    0px 5.88513px 21.2866px rgba(12, 100, 16, 0.0655718),
    0px 3.12555px 11.3052px rgba(12, 100, 16, 0.079074),
    0px 1.30061px 4.70434px rgba(12, 100, 16, 0.11);
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
  align-self: center;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 550px) {
  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1rem;
    line-height: 130%;
  }

  .illustration {
    height: 10rem;
    align-self: center;
  }

  .primaryButton {
    font-size: 0.875rem;
  }
}
