.card {
  width: 23.75rem;
  background: #fff;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.card > .contentTop {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
}

.backButton {
  width: 3rem;
  height: 3rem;
  text-align: left;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 110%;
}

.title {
  color: #606061;
}

.circle {
  margin-top: 1rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background: #dde;
  align-self: center;
}

.containerInvestedAmount {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: center;
  text-align: center;
}

.containerInvestedAmount > p {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.card > .contentBottom {
  padding: 2.5rem 1.25rem 2rem 1.25rem;
  background: #f2f4f5;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 1rem 1rem;
}

.dataText {
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

.dataText + .dataText {
  margin-top: 0.5rem;
}

.dataText > .plus {
  color: #4b93ff;
}

.dataText > .less {
  color: #b71d1d;
}

.primaryButton {
  margin-top: 2rem;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305569;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
}

.textButton {
  margin-top: 1rem;
  width: min(15rem, 100%);
  height: 3rem;
  color: #305569;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  align-self: center;
}
