ul {
  list-style: none;
}

.button {
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305569;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  margin-top: 1.5rem;
}

.button:hover {
  filter: brightness(0.9);
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 120%;
  color: #3d555a;
}

.headerBackButton {
  width: 1.5rem;
  height: 1.5rem;
}

.headerBackButton img {
  width: 1.5rem;
  height: 1.5rem;
}

.formInput {
  appearance: none;
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
  visibility: hidden;
}

.formLabel {
  width: 100%;
  padding: 1.5rem;
  min-height: 4.5rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  color: #3d555a;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border 0.2s;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  transition: background-color 0.2s;
}

.formLabel:hover {
  background-color: #667dbb;
  color: #ffffff;
}

.formLabel.formLabelTwo {
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: center;
}

.formLabel.formLabelTwo > strong {
  display: block;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
}

.formLabelActive {
  background-color: #667dbb;
  color: #ffffff;
}

.stepIndicatorBall {
  width: 0.75rem;
  height: 0.75rem;
  background-color: #ddd;
  transition: background-color 0.4s ease;
  border-radius: 50%;
}

.stepIndicatorBallActive {
  background-color: #305569;
}

.textBodyStepFive {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

@media screen and (max-width: 1455px) {
  .button {
    margin-top: -0.5rem;
  }
}

@media screen and (max-width: 1199px) {
  .button {
    margin-top: -0.6rem;
  }
}

@media screen and (max-width: 968px) {
  .button {
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 968px) {
  .button {
    margin-top: 1.8rem;
  }
}

@media screen and (max-width: 796px) {
  .button {
    margin-top: 0.8rem;
  }
}
