.cardMessage {
  padding: 1.5rem;
  max-width: 45rem;
  width: 100%;
  max-height: 18rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 0.3s;
  /* overflow: hidden; */
}

.cardMessage:hover {
  border-color: rgba(0, 0, 0, 0.2);
}

.cardMessageUnreadMessage {
  background-color: #fff;
}

.cardMessageUnreadMessage::after {
  content: "";
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #3d555a;
}

.cardMessageReadMessage {
  background-color: #e5e7ea;
  opacity: 0.7;
}

.cardMessageReadMessage > .cardMessageTitle {
  color: #6f6b6b;
}

.cardMessageReadMessage .cardMessageHeader .cardMessageTag {
  background-color: #bfbaba;
  color: #6f6b6b;
}

.cardMessageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardMessageTitle {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #1a1a1a;
}

.cardMessageUpdatedAt {
  display: block;
  font-size: 0.875rem;
  color: #6f6b6b;
}

.cardContainerMessageDescription {
  margin-top: 1.5rem;
  overflow: hidden;
  width: 100%;
}

.cardMessageDescription {
  font-size: 0.875rem;
  line-height: 150%;
  color: #504e4e;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  height: 4rem;
}

@media screen and (max-width: 550px) {
  .cardMessageTitle {
    font-size: 1.125rem;
  }

  .cardMessageUpdatedAt {
    font-size: 0.75rem;
  }

  .cardMessageUnreadMessage::after {
    width: 0.5rem;
    height: 0.5rem;
  }
}
