.h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
  color: #3d555a;
  text-align: center;
}

.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4375rem;

  height: 25rem;
}

.contentCenter > p {
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 150%;
}

.buttonShare {
  color: #858585;
  font-size: 1rem;
  font-weight: 400;
  line-height: 110%;

  margin-top: 100px;
}

.buttonShare:hover {
  color: #535353;
}

@media screen and (max-width: 360px) {
  .h2 {
    font-size: 1.125rem;
  }

  .contentCenter {
    gap: 1.25rem;
    height: 25rem;
  }

  .contentCenter > p {
    font-size: 0.9rem;
  }

  .buttonShare {
    font-size: 0.9rem;
    margin-top: 60px;
  }
}
.h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
  color: #3d555a;
  text-align: center;
}

.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4375rem;

  height: 25rem;
}

.contentCenter > p {
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 150%;
}

.buttonShare {
  color: #858585;
  font-size: 1rem;
  font-weight: 400;
  line-height: 110%;

  margin-top: 100px;
}

.buttonShare:hover {
  color: #535353;
}

@media screen and (max-width: 360px) {
  .h2 {
    font-size: 1.125rem;
  }

  .contentCenter {
    gap: 1.25rem;
    height: 25rem;
  }

  .contentCenter > p {
    font-size: 0.9rem;
  }

  .buttonShare {
    font-size: 0.9rem;
    margin-top: 60px;
  }
}
.h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
  color: #3d555a;
  text-align: center;
}

.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4375rem;

  height: 25rem;
}

.contentCenter > p {
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 150%;
}

.buttonShare {
  color: #858585;
  font-size: 1rem;
  font-weight: 400;
  line-height: 110%;

  margin-top: 100px;
}

.buttonShare:hover {
  color: #535353;
}

@media screen and (max-width: 360px) {
  .h2 {
    font-size: 1.125rem;
  }

  .contentCenter {
    gap: 1.25rem;
    height: 25rem;
  }

  .contentCenter > p {
    font-size: 0.9rem;
  }

  .buttonShare {
    font-size: 0.9rem;
    margin-top: 60px;
  }
}
.h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
  color: #3d555a;
  text-align: center;
}

.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4375rem;

  height: 25rem;
}

.contentCenter > p {
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 150%;
}

.buttonShare {
  color: #858585;
  font-size: 1rem;
  font-weight: 400;
  line-height: 110%;

  margin-top: 100px;
}

.buttonShare:hover {
  color: #535353;
}

@media screen and (max-width: 360px) {
  .h2 {
    font-size: 1.125rem;
  }

  .contentCenter {
    gap: 1.25rem;
    height: 25rem;
  }

  .contentCenter > p {
    font-size: 0.9rem;
  }

  .buttonShare {
    font-size: 0.9rem;
    margin-top: 60px;
  }
}
