.option {
  display: block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1rem 0;
}

.option:hover {
  background-color: #f0f2f5;
}
