.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 2rem;
  width: min(44.4375rem, 90%);
  height: min(46rem, 90%);

  background-color: #fff;
  border-radius: 0.5rem;
  text-align: center;
  overflow-y: auto;
  max-height: 80vh;

}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.title {
  width: 80%;
  color: #1a1a1a;
  text-align: center;
  align-self: center;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 120%;
}

.containerText {
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5rem;
  padding-right: 6rem;
}

.number {
  flex: 0 0 20%;
  color: #59A284;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}

.description {
  flex: 1;
  color: #333131;
  font-size: 1rem;
  font-weight: 300;
  line-height: 140%;
  text-align: left;
}

.illustration {
  height: 14rem;
}

.button {
  width: 12rem;
  height: 3rem;
  background: #305569;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.containerButton {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.primaryButton {
  width: min(1rem, 100%);
  height: 2rem;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

.controlButtons {
  display: flex;
  gap: 16px;

  margin-top: 24px;
}

.buttonSelected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #c4c4c4;
  cursor: pointer;
}

.selected {
  background-color: #7a7a7a;
  border-radius: 28px;
  outline: none;
}


@media screen and (max-width: 738px) {
  .modal {
    padding: 1.25rem 0.75rem;
  }

  .containerText {
    padding-left: 2rem;
    padding-right: 3rem;
  }

  .illustration {
    height: 12rem;
  }


  .primaryButton {
    padding-left: 1rem;
    width: min(1rem, 100%);
  }
}

@media screen and (max-width: 598px) {
  .title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 448px) {
  .containerText {
    padding-left: 2rem;
    padding-right: 3rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .illustration {
    transform: scale(0.8);
  }

  .controlButtons {
    margin-top: 8px;
    transform: scale(0.8);
  }

  .primaryButton {
    transform: scale(0.8);
  }

  .button {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 375px) {
  .containerText {
    padding-left: 1rem;
    padding-right: 2rem;
  }

  .number {
    line-height: 150%;
  }

  .description {
    line-height: 140%;
  }
}