.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 2rem;
  width: min(33.5rem, 95%);
  min-height: 44rem;
  background: #fff;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.headerCloseButton {
  width: 2rem;
  height: 2rem;
}

.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.headerTitle {
  font-size: 1.625rem;
  line-height: 115%;
  color: #305568;
  font-weight: 300;
}

.containerContractDataInfo {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.rowContractDataInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 150%;
  position: relative;
}

.rowContractDataInfo > strong {
  color: #504e4e;
}

.rowContractDataInfo > span {
  font-weight: 400;
  color: #6f6b6b;
}

.divider {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.formRow {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.formRow + .formRow {
  margin-top: 1.5rem;
}

.inputGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroupLabel {
  font-size: 0.875rem;
  font-weight: 300;
  color: #3d555a;
}

.inputGroupInput {
  padding-bottom: 0.25rem;
  border: 0;
  border-bottom: 1px solid #959899;
  font-size: 1rem;
  outline: 0;
  width: 100%;
}

.inputGroupInput:focus,
.inputGroupInput:focus-visible,
.inputGroupInput:focus-within {
  border-bottom: 1px solid #959899;
}

.contractStatus {
  font-size: 1.25rem;
  line-height: 120%;
  color: #305568;
}

.inputGroupWithIcon {
  position: relative;
  display: flex;
  align-items: center;
}

.inputGroupWithIconButton {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0;
  bottom: 0.5rem;
}

.inputGroupWithIconButton > img {
  width: 1.5rem;
  height: 1.5rem;
}

.containerContractInformation {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contractInformationText {
  font-size: 1.125rem;
  line-height: 150%;
  color: #3d555a;
}

.contractInformationText span {
  color: #305568;
}

.containerButtons {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.buttonWithIcon {
  width: min(18rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: 150%;
  border-radius: 2rem;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
}

.buttonWithIcon > img {
  width: 1.25rem;
  height: 1.25rem;
}

.primaryButton {
  background-color: #305568;
  color: #fff;
  transition: box-shadow 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.primaryButton:hover {
  box-shadow: none;
}

.secondButton {
  border: 1px solid #305568;
  color: #305568;
  background: transparent;
}

@media screen and (max-width: 550px) {
  .modal {
    min-height: auto;
    max-height: 85vh;
  }

  .rowContractDataInfo {
    flex-direction: column;
    gap: 0.5rem;
  }

  .buttonWithIcon {
    font-size: 0.875rem;
  }

  .buttonWithIcon > img {
    width: 1rem;
    height: 1rem;
  }
}
