.radio {
  appearance: none;
  display: none;
}

.containerRisk {
  display: block;
  width: 100%;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 1rem;
  cursor: pointer;
}

.containerRisk:hover {
  opacity: 0.7;
}

.containerRiskHeaderIcon {
  width: 2rem;
  height: 2rem;
}

.containerRiskLabel {
  font-size: 1.125rem;
  line-height: calc(1.125rem * 1.5);
  color: #3d555a;
}

.containerRiskLabel ~ .containerRiskLabel {
  display: flex;
  gap: 0.25rem;
}

.fontWeightLight {
  font-weight: 300;
}

.containerRiskLabel > .containerRiskLabel:first-child {
  margin-right: 0.25rem;
}

.containerRiskSignalPlus {
  color: #4b93ff;
}

.containerRiskSignalMinus {
  color: #db8f1b;
}

.containerRiskSignalEqual {
  color: #3d555a;
}

.button {
  margin-top: 1.5rem;
  width: min(15rem, 90%);
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
}
