.alert {
  padding: 1.5rem 2rem;
  width: 100%;
  min-height: 7rem;
  background-color: #ffe39c;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.alertIcon {
  width: 4rem;
  height: 4rem;
}

.alertText {
  margin-left: 2rem;
  flex: 1;
  font-size: 1.125rem;
  color: #333131;
}

.alertText > strong {
  color: #1a1a1a;
}

.alertLinkButton {
  margin: 0 2rem 0 2.5rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #365e72;
  border-radius: 5px;
}

.alertCloseButton,
.alertCloseButton > img {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 764px) {
  .alert {
    padding: 1.5rem;
  }

  .alertIcon {
    width: 3rem;
    height: 3rem;
  }

  .alertText {
    margin-left: 1.5rem;
    font-size: 1rem;
  }

  .alertLinkButton {
    margin: 0 1.5rem 0 2rem;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 550px) {
  .alert {
    flex-direction: column;
    gap: 1rem;
  }

  .alertIcon {
    display: none;
  }

  .alertText {
    margin-left: 0;
  }

  /* .alertLinkButton {
    display: none;
  } */

  .alertCloseButton {
    margin: 0;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
}
