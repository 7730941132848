.option {
  padding: 1rem 0.75rem;
  width: 100%;
  display: block;
  font-size: 0.75rem;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 700;
  color: #6f6b6b;
}

.option:hover {
  background-color: #f0f2f5;
  color: #333131;
}

.optionActive {
  background-color: #f0f2f5;
  color: #333131;
}
