.container {
  margin: 1.5rem 0;
  width: 100%;
  display: grid;
  place-content: center;
}

.contentCenter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.containerButtons {
  max-width: 14.375rem;
  overflow-x: auto;
}

.containerButtons::-webkit-scrollbar {
  display: none;
  appearance: none;
}

/* .containerButtons::-webkit-scrollbar-track {
  background-color: #ddd;
}

.containerButtons::-webkit-scrollbar-thumb {
  background-color: #3d555a;
  border-radius: 8px;
} */

.listButtons {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonPage {
  height: 2rem;
  width: 2rem;
  font-size: 0.875rem;
  border: 1px solid transparent;
  border-radius: 5px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.buttonPage > img {
  width: 2rem;
  height: 2rem;
}

.buttonPageActive {
  font-weight: 700;
  color: #fff;
  /* border: 1px solid transparent; */
  background-color: #3d555a;
  transition: 0.3s;
}

.buttonPageNotActive {
  color: #333131;
  border: 1px solid #dfdfdf;
  transition: 0.3s;
  opacity: 0.7;
}

.buttonPageDisabled {
  opacity: 0.3;
  cursor: not-allowed;
}

@media screen and (max-width: 450px) {
  .containerButtons {
    max-width: 8rem;
  }
}
