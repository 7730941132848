.card {
  padding: 2rem;
  width: 53.5rem;
  height: 52rem;
  background: #fff;
  overflow-y: scroll;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
}

.card::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
}

.card::-webkit-scrollbar-button:start:decrement,
.card::-webkit-scrollbar-button:end:increment {
  display: none;
}

.card::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 6px;
}

.card::-webkit-scrollbar-thumb:vertical {
  background-color: #305568;
  -webkit-border-radius: 6px;
}

.card::-webkit-scrollbar-thumb:horizontal {
  background-color: #305568;
  -webkit-border-radius: 6px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.titleHistory {
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 110%;
  color: #3d555a;
}

.historyList {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 48.75rem;
  max-height: 52rem;
  margin: 2.5rem 0;
}

.itemHistory {
  width: 100%;
  height: 11.5rem;
  padding: 1.5rem;
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
}

.questionSummary {
  min-width: 15.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  list-style: none;
}

.questionSummaryTitle {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 115%;
  color: #305569;
}

.questionSummary>p {
  font-size: 1.125rem;
}

.mdProgress {
  margin-bottom: -1rem;
  margin-top: 2rem;
  max-width: 46rem;
}

.sectionProgress {
  display: flex;
}

.metricsBallProgressOne,
.metricsBallProgressTwoAndThree,
.metricsBallProgressFour {
  display: flex;
  flex-direction: column;
}

.metricsBallProgressOne {
  align-items: flex-start;
  margin-left: 0;
}

.metricsBallProgressTwoAndThree {
  align-items: center;
  margin-left: 9.375rem;
}

.metricsBallProgressFour {
  align-items: flex-end;
  margin-left: 6rem;
}

.ballProgress {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #3d555a;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.spanProgress {
  color: #858585;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.textBallProgress {
  color: #858585;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
}

.loadingIndicator,
.responseNotInvites {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  animation: rotatingLoading 15s;

  border-radius: 50%;
  border: 8px solid #ccc;
  border-top-color: #305569;

  width: 80px;
  height: 80px;
}

@keyframes rotatingLoading {
  to {
    transform: rotate(10turn);
  }
}

.responseNotInvites {
  height: 40rem;
}

.responseNotInvites>p {
  color: #3d555a;
  font-size: 1.4rem;
  font-weight: 300;
}

@media (max-width: 749px) {
  .card {
    width: 100%;
    max-width: 100%;
  }

  .historyList {
    width: 100%
  }

  .questionSummary {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .questionSummaryTitle {
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    max-width: 100%;
  }

  .questionSummarySubtitle {
    font-size: 1rem;
  }

  .mdProgress {
    max-width: 100%;
  }

  .sectionProgress {
    max-width: 100%;
  }

  .metricsBallProgressOne,
  .metricsBallProgressTwoAndThree,
  .metricsBallProgressFour {
    max-width: 100%;
  }

  .metricsBallProgressTwoAndThree {
    margin-left: 4rem;
  }

  .metricsBallProgressFour {
    margin-left: 1rem;
  }

  .textBallProgress {
    align-items: center;
    font-size: 0.8rem;
  }

}

@media (max-width: 556px) {
  .card {
    height: 100%;
  }

  .historyList {
    height: 100%;
  }

  .itemHistory {
    height: 100%;
    min-height: 30rem;
  }

  .sectionProgress {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .metricsBallProgressOne,
  .metricsBallProgressTwoAndThree,
  .metricsBallProgressFour {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 100%;
  }

  .metricsBallProgressOne {
    align-items: flex-start;
    flex-direction: row;
    margin-left: 3rem;
    margin-top: -1.2rem;
  }

  .metricsBallProgressTwoAndThree {
    flex-direction: row;
    margin-left: 3rem;
    margin-top: 4.5rem;
  }

  .metricsBallProgressFour {
    flex-direction: row;
    margin-left: 3rem;
    margin-top: 5rem;
  }

  .textBallProgress {
    margin-left: 1rem;
  }

  .imageCheckBallProgress {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 415px) {
  .textBallContainer {
    margin-left: 0.8rem;
  }

  .questionSummarySubtitle {
    margin-right: 2rem;
  }

  .questionSummaryTitle {
    margin-right: 2rem;
  }

  .itemHistory {
    min-height: 32rem;
  }

  .textBallProgress {
    margin-left: 2rem;
    margin-right: 10rem;
  }

  .metricsBallProgressOne,
  .metricsBallProgressTwoAndThree,
  .metricsBallProgressFour {
    margin-left: 1.5rem;
  }

  .metricsBallProgressTwoAndThree,
  .metricsBallProgressFour {
    margin-top: 3.5rem;
  }

}

@media (max-width: 375px) {
  .questionSummarySubtitle {
    margin-right: 3rem;
  }

  .questionSummaryTitle {
    margin-right: 3rem;
  }

}