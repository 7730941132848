.labelType {
  font-size: 1rem;
  line-height: 120%;
}

.columnStyles {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downArrowButton.rotated {
  transform: rotate(180deg);
}

.downArrowButton {
  flex: 1;
  display: flex;
}