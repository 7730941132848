.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #fff;

  padding: 30px;
  border-radius: 8px;
  max-width: 600px;

  line-height: 150%;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: #3d555a;
}

.modalButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.modalClose {
  width: 80px;
  height: 40px;

  margin-top: 20px;
  border-radius: 8px;

  background-color: #305568;

  font-size: 1rem;
  font-weight: 500;
  color: #fff;

  cursor: pointer;
}

/* Loanding */
.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.loading {
  animation: rotatingLoading 15s;

  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #305569;

  width: 20px;
  height: 20px;
}

@keyframes rotatingLoading {
  to {
    transform: rotate(10turn);
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;

  width: 100%;
  max-width: 39.5625rem;
  height: 52rem;

  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
}

.h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 110%;
  color: #3d555a;
  text-align: center;
}

.contentCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4375rem;

  height: 25rem;
}

.contentCenter > p {
  max-width: 80%;
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 150%;
}

.contentCenter > img {
  max-width: 150%;
}

.buttonShare {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 13.44rem;
  height: 2.625rem;

  background-color: #3d555a;
  border-radius: 16px;

  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 110%;
}

.controlButtons {
  display: flex;
  gap: 16px;
}

.buttonSelected {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #c4c4c4;
  cursor: pointer;
}

.selected {
  background-color: #7a7a7a;
  width: 28px;
  border-radius: 28px;
  outline: none;
}

@media screen and (max-width: 550px) {
  .modalContent {
    transform: scale(0.8);
    padding: 20px;
    max-width: 450px;
    font-size: 1rem;
  }

  .modalClose {
    width: 60px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 360px) {
  .card {
    height: 45rem;
  }

  .modalContent {
    max-width: 320px;
    font-size: 0.68rem;
  }

  .modalClose {
    width: 40px;
    height: 30px;
    font-size: 0.8rem;
  }

  .h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .contentCenter {
    gap: 3rem;
    height: 30rem;
  }

  .contentCenter > p {
    width: 90%;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 150%;
  }

  .contentCenter > img {
    width: 300px;
  }

  .buttonShare {
    margin-bottom: 1rem;
  }
}
