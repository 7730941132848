.card {
  padding: 1.75rem;
  width: 100%;
  background: linear-gradient(270deg, #305568 0%, #396277 100%);
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  color: #fff;
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.divSpace {
  font-size: 2rem;
  line-height: 120%;
  font-weight: 300;
  margin-top: 2rem;
}

.h3 {
  font-size: 2rem;
  line-height: 120%;
  font-weight: 300;
}

.h2 {
  margin-top: 2rem;
  font-size: 2.5rem;
  line-height: 120%;
}

.walletItems {
  display: flex;
  gap: 1.5rem;
}

.walletItem {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletItemTooltipTitle {
  font-size: 0.875rem;
  line-height: 110%;
  text-transform: uppercase;
  color: #78c5ec;
}

.walletItemTooltipBody {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 150%;
  max-width: 420px;
  width: 100%;
}

.walletItemTooltipFooter > a {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #5a0f7d;
  text-decoration: underline;
}

.walletItemIcon {
  width: 2rem;
  height: 2rem;
}

.walletItemBody1 {
  font-size: 1.3rem;
  line-height: calc(1rem * 1.8);
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.walletItemBody2 {
  margin-top: 0.5rem;
  color: #78c5ec;
  font-size: 1.2rem;
  line-height: calc(0.875rem * 1.8);
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .h2 {
    margin-top: 1rem;
    font-size: 2rem;
  }

  .h3 {
    font-size: 1.5rem;
  }

  .walletItems {
    width: 100%;
    flex-wrap: wrap;
  }
}
