.input {
  width: 10rem;
  font-size: 1.5rem;
  color: #305569;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #606061;
  text-align: center;
}

.label {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 150%;
  color: #3d555a;
}

.buttonViewPassword > img {
  width: 24px;
  height: 24px;
}

.auxiliaryMessage {
  font-size: 0.875rem;
  line-height: 150%;
  color: #444;
}

.auxiliaryMessageSuccess {
  color: #305569;
}

.auxiliaryMessageError {
  color: #bc1e1e;
}

.auxiliaryMessageErrorReduceMargin {
  margin-bottom: -1.35rem;
}

.button {
  width: 16rem;
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: 0;
  transition: filter 0.2s;
  align-self: center;
}

.buttonWithoutAuxiliaryMessage {
  margin-top: 1.25rem;
}

.buttonWithAuxiliaryMessage {
  margin-top: -1rem;
}

.button:hover {
  filter: brightness(0.9);
}

@media screen and (max-width: 1710px) {
  .button {
    margin-top: 0rem;
  }
}

@media screen and (max-width: 1614px) {
  .button {
    margin-top: 1rem;
  }
  .input {
    margin-top: -0.4rem;
  }

  .label {
    margin-top: -0.4rem;
  }
}

@media screen and (max-width: 1512px) {
  .button {
    margin-top: -0.5rem;
  }
}

@media screen and (max-width: 1303px) {
  .input {
    margin-top: -0.6rem;
  }

  .label {
    margin-top: -0.6rem;
  }
}

@media screen and (max-width: 1199px) {
  .input {
    margin-top: -0.1rem;
  }

  .label {
    margin-top: -0.1rem;
  }
}

@media screen and (max-width: 796px) {
  .input {
    margin-top: -0.2rem;
  }

  .label {
    margin-top: -0.2rem;
  }
}

@media screen and (max-width: 468px) {
  .button {
    margin-top: -1.5rem;
  }
}

@media screen and (max-width: 364px) {
  .button {
    margin-top: -1.5rem;
  }
  .input {
    margin-top: -0.4rem;
  }

  .label {
    margin-top: -0.4rem;
  }
}
