.card {
  padding: 1.5rem;
  width: min(29.5rem, 90%);
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #dbd7d7;
}

.header {
  display: flex;
  justify-content: center;
  position: relative;
}

.backButton {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

.backButton > img {
  width: 2rem;
  height: 2rem;
}

.logo {
  height: 7rem;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 110%;
  color: #1a1a1a;
}

.title {
  margin-top: 2.5rem;
}

.b16 {
  font-size: 1rem;
  line-height: 150%;
}

.descriptionInput {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: #504e4e;
  text-align: right;
}

.description {
  margin-top: 1rem;
  color: #504e4e;
}

.form {
  margin-top: 1.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.label {
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  color: #333131;
  /* text-transform: uppercase; */
}

.input {
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f7f7;
  border-radius: 0.3125rem;
  border: 1px solid #dbd7d7;
  font-size: 1rem;
  color: var(--gray-300);
  outline: none;
  transition: border-color 0.2s;
}

.input:focus,
.input:focus-within {
  border-color: var(--gray-300);
}

.input::placeholder {
  color: var(--gray-100);
}

.submitButton {
  margin-top: 1.5rem;
}

.primaryButton {
  width: 100%;
  height: 3rem;
  background: #305569;
  box-shadow: 0px 47px 170px rgba(12, 100, 16, 0.030926),
    0px 19.6355px 71.0219px rgba(12, 100, 16, 0.0444282),
    0px 10.4981px 37.9717px rgba(12, 100, 16, 0.055),
    0px 5.88513px 21.2866px rgba(12, 100, 16, 0.0655718),
    0px 3.12555px 11.3052px rgba(12, 100, 16, 0.079074),
    0px 1.30061px 4.70434px rgba(12, 100, 16, 0.11);
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 425px) {
  .card {
    padding: 0.75rem;
  }

  .logo {
    height: 6rem;
  }

  .h2 {
    font-size: 1.25rem;
  }

  .b16 {
    font-size: 0.875rem;
  }

  .descriptionInput {
    font-size: 0.8125rem;
  }

  .label {
    font-size: 0.8125rem;
  }

  .primaryButton {
    font-size: 0.875rem;
  }
}
