.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(29.375rem, 95%);
  max-height: 85vh;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 4px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 120%;
  color: #1a1a1a;
}

.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e54f4f;
  border-radius: 0.3125rem;
}

.closeButton > img {
  width: 2rem;
  height: 2rem;
}

.form {
  margin-top: 2rem;
}

.formInputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.formInputGroup + .formInputGroup {
  margin-top: 1.25rem;
}

.formLabel {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--gray-300);
}

.formInput {
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f7f7;
  border-radius: 0.3125rem;
  border: 1px solid #dbd7d7;
  font-size: 1rem;
  color: var(--gray-300);
  outline: none;
  transition: border-color 0.2s;
}

.formInput:focus,
.formInput:focus-within {
  border-color: var(--gray-300);
}

.formInput::placeholder {
  color: var(--gray-100);
}

.primaryButton {
  width: 100%;
  height: 3rem;
  background: #305569;
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}
