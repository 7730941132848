.card {
  padding: 1.5rem;
  width: 23.75rem;
  background-color: #fff;
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  color: #3d555a;
  display: flex;
  flex-direction: column;
}

.backButton {
  width: 3rem;
  height: 3rem;
  text-align: left;
}

.title {
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 120%;
}

.scoreTypeItem {
  width: 100%;
  margin-top: 1.25rem;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  /* position: relative; */
}

.miniCard {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.miniCardTypeA {
  background: #91aaec;
}

.miniCardTypeB {
  background: #b1cc83;
}

.miniCardTypeC {
  background: #edc648;
}

.miniCardTypeD {
  background: #db8f1b;
}

.miniCard > h2 {
  font-size: 2rem;
  line-height: 110%;
  color: #fff;
}

.miniCard > span {
  font-size: 0.875rem;
  line-height: 150%;
  font-weight: 300;
  color: #fff;
}

.scoreTypeItemSectionRightAmount {
  font-weight: 300;
}

h2.scoreTypeItemSectionRightAmount {
  font-size: 2rem;
  line-height: 110%;
}

h3.scoreTypeItemSectionRightAmount {
  font-size: 1.25rem;
  line-height: 110%;
}

.divider {
  margin-top: 1rem;
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.scoreTypeItem > .contentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentLeft > strong {
  font-size: 3rem;
  line-height: 105%;
  text-align: center;
}

.contentLeft > span {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 150%;
  text-transform: uppercase;
}

.scoreTypeItem > .contentRight {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contentRight > strong {
  font-size: 1.5rem;
  line-height: 110%;
}

.contentRight > span {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 150%;
}

.button {
  margin-top: 2rem;
  width: min(15rem, 90%);
  height: 3rem;
  background: #305569;
  color: #ffffff;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s;
  align-self: center;
}
