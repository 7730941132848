.rowAccount {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: 70% auto;
  gap: 1rem;
}

.rowAccount + .rowAccount {
  margin-top: 1.25rem;
}

.rowAccount > div:last-child {
  margin-top: 0;
}

.formButton {
  margin-top: 2rem;
}

@media screen and (max-width: 550px) {
  .rowAccount {
    gap: 1rem;
  }
}
