.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(33.5rem, 95%);
  max-height: 85vh;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 300;
}

.headerCloseButton,
.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.messageInfo {
  margin-top: 2.5rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

.illustration {
  margin-top: 1.5rem;
  width: min(21.875rem, 100%);
  align-self: center;
}

.containerCode {
  margin-top: 2rem;
  padding: 1rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background: #e3e8f2;
  border-radius: 0.5rem;
}

.containerCode > strong {
  font-size: 1.125rem;
  line-height: 150%;
}

.containerCode > div > p {
  font-size: 1.125rem;
  line-height: 150%;
}

.containerCode > div > button,
.containerCode > div > button > img {
  width: 1.5rem;
  height: 1.5rem;
}

.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.formGrid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.inputGroupLabel {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  /* text-transform: uppercase; */
  color: var(--gray-300);
}

.text {
  text-transform: lowercase;
}

.inputGroupInput {
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f7f7;
  border-radius: 0.3125rem;
  border: 1px solid #dbd7d7;
  font-size: 1rem;
  color: var(--gray-300);
  outline: none;
  transition: border-color 0.2s;
}

.inputGroupMessage {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--gray-100);
}

.inputGroupInput:focus,
.inputGroupInput:focus-within {
  border-color: var(--gray-300);
}

.inputGroupInput::placeholder {
  color: var(--gray-100);
}

.formMessageError {
  font-size: 0.875rem;
  line-height: 150%;
  color: #f5333f;
}

.primaryButton {
  margin-top: 2.5rem;
  width: min(15rem, 100%);
  height: 3rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  background-color: #305568;
  color: #fff;
  border-radius: 2rem;
  transition: filter 0.2s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  align-self: center;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}
