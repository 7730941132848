.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.title {
  color: var(--gray-400);
}

.form {
  margin-top: 2rem;
}

.formButton {
  margin-top: 1.5rem;
}

.auxilliaryText {
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 150%;
  color: #898686;
}

@media screen and (max-width: 600px) {
  .auxilliaryText {
    font-size: 0.8125rem;
  }
}
