.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding: 1.5rem;
  width: min(29.375rem, 95%);
  max-height: 85vh;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.modal::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.modal::-webkit-scrollbar-button:start:decrement,
.modal::-webkit-scrollbar-button:end:increment {
  display: none;
}

.modal::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
  -webkit-border-radius: 6px;
}

.modal::-webkit-scrollbar-thumb:vertical {
  background-color: #305568;
  -webkit-border-radius: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-size: 1.5rem;
  line-height: 120%;
  font-weight: 300;
}

.headerCloseButton,
.headerCloseButton > img {
  width: 2rem;
  height: 2rem;
}

.closeButtonRight {
  align-self: flex-end;
}

.dataContainerColumn {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: #3d555a;
}

.dataContainerInfoScore {
  margin-top: 16px;
  padding: 0.8rem;
  border-radius: 15px;
}

.sectionTitle {
  margin: 2rem 0 1.5rem 0;
  font-size: 1.25rem;
  line-height: 120%;
  font-weight: 300;
}

.containerDataGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1.5rem;
}

.containerButtons {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.messageLabel {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 764px) {
  .modal {
    max-height: 85vh;
    overflow: auto;
  }
}
