.title {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
}

.boxTir {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paragraph {
  font-size: 4.6875rem;
  font-weight: 500;
  color: #3d555a;
}

.paragraph__two {
  font-weight: 300;
  font-size: 1.5625rem;
  margin: 25px 0 0 12px;
}

.label {
  max-width: 20rem;
  font-size: 1.125rem;
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  animation: rotatingLoading 15s;

  border-radius: 50%;
  border: 6px solid #ccc;
  border-top-color: #305569;

  width: 50px;
  height: 50px;
}

@keyframes rotatingLoading {
  to {
    transform: rotate(10turn);
  }
}