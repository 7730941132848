.header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.backButton {
  width: 2.5rem;
  height: 2.5rem;
  text-align: left;
}

.backButton > img {
  width: 2rem;
  height: 2rem;
}

.title {
  color: var(--gray-400);
}

.form {
  margin-top: 2rem;
}

.auxilliaryText {
  margin-top: 0.75rem;
  color: var(--gray-100);
}

.auxilliaryTextCheck {
  color: #305569;
}

.auxilliaryText + .auxilliaryText {
  margin-top: 0.25rem;
}

.submitButton {
  margin-top: 1.5rem;
}

.passwordVisibilityButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .auxilliaryText {
    font-size: 0.8125rem;
  }

  .submitButton {
    margin-top: 2rem;
  }
}
