.mainContainer {
  padding: 0 1rem;
  margin: 3rem auto 1.5rem auto;
  width: min(40.5rem, 100%);
  display: flex;
  flex-direction: column;
}

.logo {
  height: 5rem;
  align-self: center;
}

.h1 {
  margin-top: 4.5rem;
  font-size: 2rem;
  line-height: 110%;
  font-weight: 700;
  color: #1a1a1a;
}

.sectionForm {
  margin: 4.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.h24 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 110%;
}

.b16 {
  font-size: 1rem;
  line-height: 150%;
}

.b14 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;
}

.card {
  padding: 1.5rem;
  width: min(29.5rem, 90%);
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #dbd7d7;
}

.formInputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.formInputGroup + .formInputGroup {
  margin-top: 1.25rem;
}

.formLabel {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--gray-300);
}

.formInput {
  padding: 0 0.75rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f7f7;
  border-radius: 0.3125rem;
  border: 1px solid #dbd7d7;
  font-size: 1rem;
  color: var(--gray-300);
  outline: none;
  transition: border-color 0.2s;
}

.formInput:focus,
.formInput:focus-within {
  border-color: var(--gray-300);
}

.formInput::placeholder {
  color: var(--gray-100);
}

.primaryButton {
  width: 100%;
  height: 3rem;
  background: #305569;
  box-shadow: 0px 47px 170px rgba(12, 100, 16, 0.030926),
    0px 19.6355px 71.0219px rgba(12, 100, 16, 0.0444282),
    0px 10.4981px 37.9717px rgba(12, 100, 16, 0.055),
    0px 5.88513px 21.2866px rgba(12, 100, 16, 0.0655718),
    0px 3.12555px 11.3052px rgba(12, 100, 16, 0.079074),
    0px 1.30061px 4.70434px rgba(12, 100, 16, 0.11);
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  transition: filter 0.2s;
}

.primaryButton:hover {
  filter: brightness(0.9);
  box-shadow: none;
}

@media screen and (max-width: 550px) {
}
