.title {
  color: #fff;
  font-weight: 300;
  font-size: 1.5rem;
}

.messageEmpty {
  font-size: 1rem;
  color: #898686;
}

.filterButton {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: #898686;
  text-transform: uppercase;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
}

.filterButtonActive {
  color: #305568;
  border-bottom-color: #305568;
}

/* .filterButton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
} */
