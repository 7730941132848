.dot {
  display: block;
  position: absolute;
  top: 10px;
  left: 16px;
  background-color: #f62525;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
}
