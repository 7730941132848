.walletItem {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletItemIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.walletItemBody2 {
  margin-top: 0.5rem;
  color: #78c5ec;
  font-size: 0.875rem;
  line-height: calc(0.875rem * 1.5);
  text-transform: uppercase;
  text-align: center;
}

.walletItemBody1 {
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.walletItemTooltipTitle {
  font-size: 0.875rem;
  line-height: 110%;
  text-transform: uppercase;
  color: #78c5ec;
}

.walletItemTooltipBody {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 150%;
  max-width: 420px;
  width: 100%;
}
