.cardTransactionsHeaderInput {
  appearance: none;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.cardTransactionsHeaderLabel {
  flex: 1;
  height: 100%;
  font-size: 1.5rem;
  line-height: 140%;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #305568;
}

.cardTransactionsHeaderInput:checked ~ .cardTransactionsHeaderLabel {
  font-weight: 700;
}

.cardTransactionsHeaderInput:checked ~ .cardTransactionsHeaderLabel::after {
  content: "";
  /* width: 100%; */
  height: 4px;
  background-color: #305568;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cardTransactionsMonthsInput {
  appearance: none;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.cardTransactionsMonthsLabel {
  flex: 1;
  padding: 0.5rem 0;
  height: 100%;
  font-size: 1.125rem;
  line-height: 120%;
  font-weight: 300;
  color: #606061;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cardTransactionsMonthsInput:checked ~ .cardTransactionsMonthsLabel {
  color: #305568;
  font-weight: 700;
}

.cardTransactionsMonthsInput:checked ~ .cardTransactionsMonthsLabel::after {
  content: "";
  /* width: 100%; */
  height: 4px;
  background-color: #305568;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.cardTransactionsDownloadButton {
  font-size: 1.25rem;
  color: #305568;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.cardTransactionsDownloadButton > img {
  width: 2rem;
  height: 2rem;
}

.cardTransactionInfoMessage {
  margin: 5rem 0;
  font-size: 1rem;
  line-height: 150%;
  color: #444;
  text-align: center;
}

.labelType {
  font-size: 1.125rem;
  line-height: 120%;
}

/* .labelAmount {
  font-size: 1.125rem;
  line-height: 120%;
} */

@media (max-width: 500px) {
  .cardTransactionsHeaderLabel {
    font-size: 1rem;
    line-height: 150%;
  }

  .cardTransactionsMonthsLabel {
    font-size: 1rem;
    line-height: 150%;
  }

  .cardTransactionInfoMessage {
    font-size: 0.875rem;
  }

  .labelType {
    font-size: 1rem;
  }
}
